var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("zg-transition-block", [
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showTerms,
          expression: "showTerms",
        },
      ],
      key: "terms",
      class: [
        "terms",
        {
          "terms--on-campaign-page": _vm.onCampaignPage,
        },
      ],
      domProps: { innerHTML: _vm._s(_vm.terms) },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }